import Chart from 'chart.js/auto'
import zoomPlugin from 'chartjs-plugin-zoom'
import 'chartjs-adapter-luxon'
import axios from 'axios'
//const http = require('http')
//const axios = require('axios')

Chart.register(zoomPlugin)

var chart = null
//var last_block
//var first_block
const o_block = 16969712
const o_timestamp = new Date('2023-04-03 16:35:35').getTime()
const type_limits = {
	'1d': 96,
	'7d': 7,
	'30d': 30,
	'90d': 90,
}
const type_lables = {
	'1d': '1 Day NFT Index',
	'7d': '7 Days NFT Index',
	'30d': '30 Days NFT Index',
	'90d': '90 Days NFT Index',
}

const date_options = {
	month: 'short',
	day: 'numeric',
	hour: 'numeric',
	minute: '2-digit',
	hour12: true,
}
async function getNftIndex(type, start) {
	let params = { type: type, start: start, limit: type_limits[type] }
	let res = await axios.get('http://macromarkets.org:8080/', {params: params})
	//let res = await axios.get('https://79k17d9394.zicp.fun/api', {params: params})
	//et res = await axios.get('http://zexudw.natappfree.cc/api/', {params: params})
	//console.log(res)
	//return res.data.result
	let blocks = []
	let index = []
	let mark = []
	let dates = []
	for (let i=0; i<res.data.result.length; i++) {
		//index.push({x: i, y: res[i-1].nft_index})
		blocks.push(res.data.result[i].end_block)
		index.push(Math.floor(res.data.result[i].nft_index * 10000 + 0.5) / 100)
		mark.push(res.data.result[i].mark)
		dates.push(res.data.result[i].date)
		//dates.push(res.data.result[i].date.slice(0, 19).replace('T', ' '))
		//dates.push(new Date(res.data.result[i].date).toLocaleDateString('en-US', date_options))
	}
	//console.log(dates)
	return {blocks: blocks, index: index, mark: mark, dates: dates}
}

const chartAreaBorder = {
	id: 'chartAreaBorder',
	beforeDraw(chart, args, options) {
	  const {ctx, chartArea: {left, top, width, height}} = chart;
	  ctx.save();
	  ctx.strokeStyle = options.borderColor;
	  ctx.lineWidth = options.borderWidth;
	  ctx.setLineDash(options.borderDash || []);
	  ctx.lineDashOffset = options.borderDashOffset;
	  ctx.strokeRect(left, top, width, height);
	  ctx.restore();
	}
};

window.showChart = async (type, start = -1) => {
	//console.log('showChart ' + type)
	let res = await getNftIndex(type, start)
	//console.log(res)
	let first_block = res.blocks[0]
	let last_block = res.blocks[res.blocks.length - 1]
	let first_timestamp = new Date(res.dates[0]).getTime()
	let last_timestamp = new Date(res.dates[res.dates.length - 1]).getTime()
	//console.log(first_block)
	//console.log(last_block)
	//console.log(o_block)
	//console.log(first_timestamp)
	//console.log(last_timestamp)
	//console.log(o_timestamp)
	let timeBlockRatio = (last_timestamp - first_timestamp) / (last_block - first_block)
	//console.log(timeBlockRatio)
	//console.log(last_timestamp - first_timestamp)
	//console.log((first_timestamp - o_timestamp) / (first_block - o_block))

	var zoomOptions = {
		limits: {
		  //x: {min: min_block, max: last_block},// minRange: 75},
		  x: {min: o_timestamp, max: last_timestamp},
		  //y: {min: -200, max: 200, minRange: 50}
		},
		pan: {
			enabled: true,
			mode: 'x',
			async onPanComplete({chart}) {
				//console.log('pan')
				var {min, max} = chart.scales.x
				//console.log(min)
				//console.log(max)
				//console.log(max-min)
				let start_block = Math.floor((min - first_timestamp) / timeBlockRatio + first_block)
				//console.log(start_block)
				let res = await getNftIndex(type, start_block)
				first_block = res.blocks[0]
				last_block = res.blocks[res.blocks.length - 1]
				first_timestamp = new Date(res.dates[0]).getTime()
				last_timestamp = new Date(res.dates[res.dates.length - 1]).getTime()
				//chart.data.labels = res.blocks
				chart.data.labels = res.dates
				chart.data.datasets[0].data = res.index
				chart.data.datasets[0].pointBackgroundColor = function(context) {
					if (res.mark[context.dataIndex] == 1) {
						return 'red'
					} else {
						return '#A0A0A0'
					}
				}
				chart.data.datasets[0].pointRadius = function(context) {
					if (res.mark[context.dataIndex] == 1) {
						return 2
					} else {
						return 0
					}
				}
				chart.data.datasets[0].tooltip.callbacks.label = function(context) {
					return `NFT Index: ${res.index[context.dataIndex]}`
				}
				chart.data.datasets[0].tooltip.callbacks.afterLabel = function(context) {
					return `Block number: ${res.blocks[context.dataIndex]}`
				}
				chart.options.scales.y.max = Math.ceil(Math.max(...res.index) + 10)
			    chart.stop(); // make sure animations are not running
    			chart.update('none');
				// This update is needed to display up to date zoom level in the title.
				// Without this, previous zoom level is displayed.
				// The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
				//chart.update('none');
		  	}
		},
		zoom: {
			//enabled: true,
			//mode: 'xy',
			wheel: {
			  //enabled: true,
			},
		},
	}

	const scales = {
		x: {
		  //position: 'bottom',
		  border: {
			display: true
		  },
		  grid: {
			color: '#A0A0A040',
			display: false,
			drawOnChartArea: true,
			drawTicks: true,
		  },
		  //min: first_block,
		  //max: last_block,
		  type: 'time',
		  time: {
			unit: 'minute',
			displayFormats: {
				minute: 'LLL d t',
			},
		  },
		  ticks: {
			autoSkip: true,
			maxTicksLimit: 24,
			source: 'labels',
			//autoSkipPadding: 50,
			//maxRotation: 0,
			/*callback: function(value, index, ticks) {
				//console.log(value)
				//console.log(index)
				//console.log(res.dates[index])
				//return Math.floor(value)
				return res.dates[index]
			},*/
			//major: {
			//	enabled: true,
			//},
		  },
		},
		y: {
			border: {
			  display: true
			},
			grid: {
			  color: '#A0A0A040',
			  display: true,
          	  drawOnChartArea: true,
          	  drawTicks: true,
			},
			min: 60,
			max: Math.ceil(Math.max(...res.index)) + 10,
		}
	}
	//console.log(index)
	//let v = math.std(index, 'unbiased')
	if (chart) {
		chart.destroy()
		chart = null
	}
	chart = new Chart(
    	document.getElementById('nftindex'),
    	{
    		type: 'line',
    		data: {
				//labels: res.blocks,
				labels: res.dates,
        		datasets: [{
					label: type_lables[type],
					data: res.index,
					lineTension: 0.2,
					pointBackgroundColor: function(context) {
						//console.log(context.dataIndex)
						//var index = context.dataIndex;
						if (res.mark[context.dataIndex] == 1) {
							return 'red'
						} else {
							return '#A0A0A0'
						}
					},
					pointRadius: function(context) {
						if (res.mark[context.dataIndex] == 1) {
							return 2
						} else {
							return 0
						}
					},
					borderWidth: 1,
					borderColor: '#A0A0A0',
					backgroundColor: '#A0A0A0',
					tooltip: {
						callbacks: {
							label: function(context) {
								return `NFT Index: ${res.index[context.dataIndex]}`
							},
							afterLabel: function(context) {
								return `Block number: ${res.blocks[context.dataIndex]}`
							}
						}
					}
        		}]
    		},
			options: {
				scales: scales,
				plugins: {
				  zoom: zoomOptions,
				  legend: {
					display: true,
					labels: {
						boxWidth: 12,
						boxHeight: 12,
					}
				  },
				  chartAreaBorder: {
					borderColor: '#A0A0A080',
					borderWidth: 0.5,
					//borderDash: [5, 5],
					//borderDashOffset: 2,
				  },
				},
				interaction: {
					intersect: false,
					mode: 'x',
				},
				/*onClick: (e, els) => {
					console.log(e)
					console.log(els)
					console.log(type)
					if (type == '1d' || els.length == 0) {
						return
					}
					let start = e.chart.data.labels[els[0].index] - 75*47
					console.log(start)
					showChart('1d', start)
				},*/
			},
			plugins: [chartAreaBorder],
    	}
  	);
}

(async function() {
	//document.getElementById("button_1d").onclick = showChart
	//document.getElementById("button_7d").onclick = showChart
	//document.getElementById("button_30d").onclick = showChart
	//document.getElementById("button_90d").onclick = showChart

	await showChart('1d')
	/*let res = await getNftIndex('1d', -1, 96)
	console.log(res)
	//let blocks = []
	//let index = []
	//for (let i=0; i<res.length; i++) {
		//index.push({x: i, y: res[i-1].nft_index})
	//	blocks.push(res[i].end_block)
	//	index.push(res[i].nft_index*100)
	//}
	first_block = res.blocks[0]
	last_block = res.blocks[res.blocks.length - 1]
	console.log(last_block)
	console.log(min_block)

	var zoomOptions = {
		limits: {
		  x: {min: min_block, max: last_block, minRange: 75},
		  //y: {min: -200, max: 200, minRange: 50}
		},
		pan: {
			enabled: true,
			mode: 'x',
			async onPanComplete({chart}) {
				console.log('pan')
				var {min, max} = chart.scales.x
				console.log(min)
				console.log(max)
				min = Math.floor(min)
				max = Math.floor(max)
				let res = await getNftIndex('1d', min, 96)
				chart.data.labels = res.blocks
				chart.data.datasets[0].data = res.index
			    chart.stop(); // make sure animations are not running
    			chart.update('none');
				// This update is needed to display up to date zoom level in the title.
				// Without this, previous zoom level is displayed.
				// The reason is: title uses the same beforeUpdate hook, and is evaluated before zoom.
				//chart.update('none');
		  	}
		},
		zoom: {
			//enabled: true,
			//mode: 'xy',
			wheel: {
			  //enabled: true,
			},
		},
	}

	const scales = {
		x: {
		  //position: 'bottom',
		  min: first_block,
		  max: last_block,
		  type: 'linear',
		  ticks: {
			autoSkip: true,
			//autoSkipPadding: 50,
			maxRotation: 0,
			callback: function(value, index, ticks) {
				return Math.floor(value)
			},
		  },
		},
	}
	//console.log(index)
	//let v = math.std(index, 'unbiased')
	chart = new Chart(
    	document.getElementById('nftindex'),
    	{
    		type: 'line',
    		data: {
        		//labels: data.map(row => row.year),
				labels: res.blocks,
        		datasets: [{
					label: 'NFT Index',
					data: res.index,
        		}]
    		},
			options: {
				scales: scales,
				plugins: {
				  zoom: zoomOptions,
				},
			},
    	}
  	);*/
})();
